<template>
  <page-header-wrapper class="month-discount" :title='this.$route.meta.title'>
    <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}">
      <a-tab-pane key="1" :tab="'运营业绩' + (presidentTotal > -1 ? '(' + presidentTotal + '笔)' : '') ">
        <president-list @setPresidentTitle="setPresidentTitle"></president-list>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="'服务业绩' + (centerTotal > -1 ? '(' + centerTotal + '笔)' : '') ">
        <center-list @setCenterTitle="setCenterTitle"></center-list>
      </a-tab-pane>
    </a-tabs>
  </page-header-wrapper>
</template>

<script>
import PresidentList from "./presidentList.vue"
import CenterList from "./centerList.vue"

export default {
  name: 'PerformancePresidentList',
  components: {
    PresidentList, CenterList
  },
  data() {
    return {
      presidentTotal: -1,
      centerTotal: -1
    }
  },
  methods: {
    setPresidentTitle(value) {
      this.presidentTotal = value
    },
    
    setCenterTitle(value) {
      this.centerTotal = value
    }
  },
}
</script>
<style lang="less">
.month-discount {
  .ant-tabs-nav-wrap {
    padding: 0 26px;
  }
  .ant-tabs-bar {
    margin: unset !important;
  }
  >.ant-pro-grid-content>.ant-pro-page-header-wrap-children-content {
    margin: unset !important;
  }
}
</style>